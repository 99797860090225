import React from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

import imgSuccess from '../../assets/img/success.png';
import imgError from '../../assets/img/error.png';

export function SuccessBox({ show, message, onCancel }) {
  return (
    <Modal show={show} centered backdrop="static" style={{textAlign: 'center'}}>
        <Modal.Title>
          <Image className='rounded-circle' width={150} height={150} src={imgSuccess}/>
        </Modal.Title>
        <Modal.Body style={{ fontSize: '18px' }}>
          <h5>
            {message}
          </h5>
          <div style={{marginTop: '20px'}}>
            <Button className="btn btn-success" onClick={onCancel}>
              OK
            </Button>
          </div>
        </Modal.Body>
    </Modal>
  );
}


export function ErrorBox({ show, message, onCancel }) {

  const history = useHistory();

  //se a mensagem de erro conter o código 403, é um possível erro de TOKEN_INVALID
  if(typeof message === "string" && message.includes("403")) {
    //Então irá fazer uma solicitação pro MS de auth para confirmar se o token é inválido
    const verificarToken = async () => {
      try {
        const response = await api.get('/authentication/token', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
      } catch (error) {
        if(error.response.data !== 'Token válido') {
          localStorage.clear();
          history.push('/login#expired');
        }
      }
    };

    verificarToken();
  }

  return (
    <Modal show={show} centered backdrop="static" style={{textAlign: 'center'}}>
        <Modal.Title style={{ paddingTop: "15px"}}>
          <Image className='rounded-circle' width={130} height={130} src={imgError}/>
        </Modal.Title>
        <Modal.Body style={{ fontSize: '18px' }}>
          <h5>
            {message}
          </h5>
          <div style={{marginTop: '20px'}}>
            <Button className="btn btn-error" onClick={onCancel}>
              OK
            </Button>
          </div>
        </Modal.Body>
    </Modal>
  );
}

export function ConfirmationBox({ show, message, onConfirm, onCancel }) {
  return (
    <Modal show={show} centered>
      <Modal.Title style={{ paddingLeft: '15px', paddingTop: '10px' }}>Pergunta do Sistema!</Modal.Title>
      <Modal.Body style={{ fontSize: '18px' }}>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}