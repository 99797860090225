import React from 'react';
import { BrowserRouter as Routers, Route } from 'react-router-dom';
import Router from './pages/Router';

export default function App() {

  return (
    <Routers>
      <Route path="/" component={Router} />
    </Routers>
  );
}