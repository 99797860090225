import { jwtDecode }from 'jwt-decode';

export const getDecodedToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }
  return null;
};

export const hasPermission = (decodedToken, requiredPermission) => {
  if (!decodedToken || !decodedToken.permission) {
    return false;
  }
  return decodedToken.permission === requiredPermission;
};